@import '../../styles/constants.scss';

#branch-selection {
    background-color: $dark;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .user-actions {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: $background;
        padding: 10px 20px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user-info {
            display: flex;
        }

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 20px;
        }

        .texts {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .welcome {
            font-size: 12px;
            color: $white;
        }

        .user-name {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
            color: $white;
        }

        p {
            text-align: center;
        }



        .logout {
            border: none;
            font-size: 11px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .no-fares {
        text-align: center;
        font-size: 20px;
        color: $white;
    }

    .branches-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

    }

}

@media screen and (max-width: 768px) {
    #branch-selection {
        .branches-card {
            flex-direction: column;
            max-height: calc(100dvh - 272px);
            overflow: scroll;
            flex-wrap: initial;
            align-items: initial;
            justify-content: initial;
        }

        .user-actions {
            position: fixed;
            bottom: 0px;
            right: 0px;
            width: calc(100vw - 40px);
            background-color: $background;
            padding: 10px 20px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .texts {
                align-items: flex-start;
            }

            .user-name,
            .welcome {
                color: $white;
            }

        }
    }
}