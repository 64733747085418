@import '../../../../styles/constants.scss';

.modal-copy {
    .ant-modal-body {
        max-height: 60vh;
        overflow: auto;
        scrollbar-color: white transparent;
        scrollbar-width: thin;
    }
}

.question-card {
    border-radius: .5rem;
    background-color: #3c434c;
    border: 2px solid $borders;
    outline: none;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    p {
        margin-left: 5px;
        font-weight: bold;
        color: white;
    }
}

.icon-progress {
    width: 30px;
}